import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import type { RootState } from '~/store'

export const state = () => ({
  registered: false,
  uid: '',
  avatar: '',
  displayName: '',
})

export type AuthState = ReturnType<typeof state>

export const getters: GetterTree<AuthState, RootState> = {
  /**
   * Firebase Authentication でログイン状態かどうか
   */
  isAuthenticated(state) {
    return !!state.uid
  },
  /**
   * Workations にユーザー登録済みかどうか
   */
  isRegistered(state) {
    return !!state.registered
  },
  avatarImageURL(state) {
    return state.avatar
  },
  displayName(state) {
    return state.displayName
  },
  loginUid(state) {
    return state.uid
  },
}

export const actions: ActionTree<AuthState, RootState> = {
  // store書き込み
  authenticate({ commit }, user) {
    commit('login', user)
  },
  register({ commit }, value) {
    commit('register', value)
  },
  async logout({ commit }) {
    await this.$fire.auth.signOut()
    commit('logout')
  },
}

export const mutations: MutationTree<AuthState> = {
  login(state, user) {
    state.uid = user.uid
  },
  register(state, value) {
    state.registered = !!value.registered
    state.avatar = value.avatar || ''
    state.displayName = value.displayName || ''
  },
  logout(state) {
    state.registered = false
    state.uid = ''
    state.avatar = ''
    state.displayName = ''
  },
}
