import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=73ba8699&scoped=true&"
var script = {}
import style0 from "./Nav.vue?vue&type=style&index=0&id=73ba8699&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73ba8699",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Nav: require('/home/runner/work/workation-web/workation-web/app/components/common/Nav.vue').default,IconFacebook: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconFacebook.vue').default,IconTwitter: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconTwitter.vue').default,IconInstagram: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconInstagram.vue').default,ChangeLocale: require('/home/runner/work/workation-web/workation-web/app/components/common/ChangeLocale.vue').default,Logo: require('/home/runner/work/workation-web/workation-web/app/components/figure/Logo.vue').default})
