
export default {
  props: {
    userFacebook: {
      type: String,
      default: '',
    },
    userTwitter: {
      type: String,
      default: '',
    },
    userYoutube: {
      type: String,
      default: '',
    },
    userSpotify: {
      type: String,
      default: '',
    },
    userGithub: {
      type: String,
      default: '',
    },
    userNote: {
      type: String,
      default: '',
    },
  },
  computed: {
    facebook() {
      return this.userFacebook !== ''
        ? `https://www.facebook.com/${encodeURI(this.userFacebook)}`
        : ''
    },
    twitter() {
      return this.userTwitter !== ''
        ? `https://twitter.com/${encodeURI(this.userTwitter)}`
        : ''
    },
    youtube() {
      return this.userYoutube !== ''
        ? `https://www.youtube.com/channel/${encodeURI(this.userYoutube)}`
        : ''
    },
    spotify() {
      return this.userSpotify !== ''
        ? `https://open.spotify.com/user/${encodeURI(this.userSpotify)}`
        : ''
    },
    github() {
      return this.userGithub !== ''
        ? `https://github.com/${encodeURI(this.userGithub)}`
        : ''
    },
    note() {
      return this.userNote !== ''
        ? `https://note.com/${encodeURI(this.userNote)}`
        : ''
    },
  },
  methods: {
    getFillColor(link) {
      return link ? 'var(--black)' : 'var(--grayDarken)'
    },
  },
}
