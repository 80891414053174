
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
export default {
  watch: {
    $route: function () {
      this.menuClose()
    },
  },
  mounted() {
    clearAllBodyScrollLocks()
  },
  methods: {
    async menuClose() {
      clearAllBodyScrollLocks()
      await this.$store.dispatch('menu/set', false)
    },
  },
}
