import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=acf38be6&scoped=true&"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=acf38be6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf38be6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLink: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconLink.vue').default,Nav: require('/home/runner/work/workation-web/workation-web/app/components/common/Nav.vue').default,Nav: require('/home/runner/work/workation-web/workation-web/app/components/common/Nav.vue').default,Footer: require('/home/runner/work/workation-web/workation-web/app/components/common/Footer.vue').default})
