
import { disableBodyScroll } from 'body-scroll-lock'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      logoShrink: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      loginUid: 'auth/loginUid',
    }),
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async menuOpen() {
      disableBodyScroll(document.querySelector('html'))
      await this.$store.dispatch('menu/set', true)
    },
    handleScroll() {
      window.requestAnimationFrame(() => {
        const scrollTop = window.scrollY
        if (scrollTop < 200) {
          this.logoShrink = false
          return
        }
        this.logoShrink = true
      })
    },
    // async logOut() {
    //   await this.$store.dispatch('auth/logout')
    //   this.$router.push('/')
    // },
  },
}
