
export default {
  computed: {
    availableLocales() {
      // current this.$i18n.locale
      return this.$i18n.locales
    },
  },
  methods: {
    isSelected(code) {
      return this.$i18n.locale === code
    },
    async changeLocale(e) {
      await this.$i18n.setLocale(e.target.value)
      location.reload()
    },
  },
}
