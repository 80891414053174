import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=b5cbf8b8&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=b5cbf8b8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5cbf8b8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InformationBoard: require('/home/runner/work/workation-web/workation-web/app/components/common/InformationBoard.vue').default,Logo: require('/home/runner/work/workation-web/workation-web/app/components/figure/Logo.vue').default,IconAccount: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconAccount.vue').default,IconLink: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconLink.vue').default,Header: require('/home/runner/work/workation-web/workation-web/app/components/common/Header.vue').default})
