import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import type { RootState } from '~/store'

export const state = () => ({
  menuState: false,
})

export type StateType = ReturnType<typeof state>

export const getters: GetterTree<StateType, RootState> = {
  state: (state) => state.menuState,
}

export const mutations: MutationTree<StateType> = {
  set(state, val) {
    state.menuState = val
  },
}

export const actions: ActionTree<StateType, RootState> = {
  set({ commit }, state) {
    commit('set', state)
  },
}
