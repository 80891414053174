
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      prefectures: [],
    }
  },
  computed: {
    ...mapGetters({
      categoryFetching: 'categories/fetching',
      categoryDone: 'categories/done',
      categories: 'categories/items',
    }),
  },
  mounted() {
    if (!this.categoryDone && !this.categoryFetching) {
      this.categoryFetch()
    }
    this.getArea()
  },
  methods: {
    ...mapActions({
      categoryFetch: 'categories/fetch',
    }),
    async getArea() {
      const res = await this.$contentful.getEntries({
        locale: this.$i18n.locale,
        content_type: 'area-post',
        order: 'fields.code',
        select: 'sys.id,fields.title,fields.code,fields.area',
      })
      this.prefectures = res.items.map((item) => {
        return {
          label: item.fields.title,
          value: item.sys.id,
        }
      })
    },
  },
}
