import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import type { Entry } from 'contentful'
import type { RootState } from '~/store'

export const state = () => ({
  items: [] as Entry<Workations.OccupationFields>[],
  fetching: false,
  done: false,
})

export type OccupationsState = ReturnType<typeof state>

export const getters: GetterTree<OccupationsState, RootState> = {
  items: (state) => state.items,
  fetching: (state) => state.fetching,
  done: (state) => state.done,
}

export const mutations: MutationTree<OccupationsState> = {
  SET_ITEMS(state, items: Entry<Workations.OccupationFields>[]) {
    state.items = items
    state.fetching = false
    state.done = true
  },
  FETCHING(state) {
    state.fetching = true
  },
}

export const actions: ActionTree<OccupationsState, RootState> = {
  async fetch({ commit }) {
    commit('FETCHING')
    const result = await this.$contentful
      .getEntries<Workations.OccupationFields>({
        locale: this.$i18n.locale,
        content_type: 'occupation',
        'fields.name[exists]': true,
        limit: 100,
        select: 'sys.id,fields.name,fields.slug',
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        return null
      })
    commit(
      'SET_ITEMS',
      result
        ? result.items.map((item) => {
            return {
              slug: item.fields.slug,
              name: item.fields.name,
            }
          })
        : []
    )
  },
}
