import { render, staticRenderFns } from "./SearchSuggest.vue?vue&type=template&id=d0bc5cc8&"
import script from "./SearchSuggest.vue?vue&type=script&lang=js&"
export * from "./SearchSuggest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Option: require('/home/runner/work/workation-web/workation-web/app/components/common/Option.vue').default})
