
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      loginUid: 'auth/loginUid',
    }),
  },
}
