import type { Plugin } from '@nuxt/types'

const auth: Plugin = async ({ app, store }) => {
  if (process.static) {
    return
  }
  if (process.server || store.getters['auth/isAuthenticated']) {
    return
  }
  // ログインしてる？
  const authUser = await new Promise<firebase.default.User | null>(
    (resolve) => {
      app.$fire.auth.onAuthStateChanged((user) => {
        resolve(user)
      })
    }
  )
  if (authUser !== null) {
    await store.dispatch('auth/authenticate', authUser)
    // ログインしてるけど、dbにある？
    const doc = await app.$fire.firestore
      .doc(`users/${authUser.uid}`)
      .get()
      .catch((error) => {
        app.$sentry.captureException(error)
        return null
      })
    await store.dispatch('auth/register', {
      registered: doc ? doc.exists : false,
      avatar: doc ? doc.get('avatar') : '',
      displayName: doc ? doc.get('display_name') : '',
    })
  }
}

export default auth
