
export default {
  props: {
    to: {
      type: [Object, String],
      default: null,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
}
