const prefs = [
  { code: '01', name: '北海道', en: 'Hokkaido' },
  { code: '02', name: '青森県', en: 'Aomori' },
  { code: '03', name: '岩手県', en: 'Iwate' },
  { code: '04', name: '宮城県', en: 'Miyagi' },
  { code: '05', name: '秋田県', en: 'Akita' },
  { code: '06', name: '山形県', en: 'Yamagata' },
  { code: '07', name: '福島県', en: 'Fukushima' },
  { code: '08', name: '茨城県', en: 'Ibaraki' },
  { code: '09', name: '栃木県', en: 'Totigi' },
  { code: '10', name: '群馬県', en: 'Gumma' },
  { code: '11', name: '埼玉県', en: 'Saitama' },
  { code: '12', name: '千葉県', en: 'Chiba' },
  { code: '13', name: '東京都', en: 'Tokyo' },
  { code: '14', name: '神奈川県', en: 'Kanagawa' },
  { code: '15', name: '新潟県', en: 'Niigata' },
  { code: '16', name: '富山県', en: 'Toyama' },
  { code: '17', name: '石川県', en: 'Ishikawa' },
  { code: '18', name: '福井県', en: 'Fukui' },
  { code: '19', name: '山梨県', en: 'Yamanasi' },
  { code: '20', name: '長野県', en: 'Nagano' },
  { code: '21', name: '岐阜県', en: 'Gifu' },
  { code: '22', name: '静岡県', en: 'Sizuoka' },
  { code: '23', name: '愛知県', en: 'Aiti' },
  { code: '24', name: '三重県', en: 'Mie' },
  { code: '25', name: '滋賀県', en: 'Shiga' },
  { code: '26', name: '京都府', en: 'Kyoto' },
  { code: '27', name: '大阪府', en: 'Osaka' },
  { code: '28', name: '兵庫県', en: 'Hyogo' },
  { code: '29', name: '奈良県', en: 'Nara' },
  { code: '30', name: '和歌山県', en: 'Wakayama' },
  { code: '31', name: '鳥取県', en: 'Tottori' },
  { code: '32', name: '島根県', en: 'Shimane' },
  { code: '33', name: '岡山県', en: 'Okayama' },
  { code: '34', name: '広島県', en: 'Hiroshima' },
  { code: '35', name: '山口県', en: 'Yamaguchi' },
  { code: '36', name: '徳島県', en: 'Tokushima' },
  { code: '37', name: '香川県', en: 'Kagawa' },
  { code: '38', name: '愛媛県', en: 'Ehime' },
  { code: '39', name: '高知県', en: 'Kochi' },
  { code: '40', name: '福岡県', en: 'Fukuoka' },
  { code: '41', name: '佐賀県', en: 'Saga' },
  { code: '42', name: '長崎県', en: 'Nagasaki' },
  { code: '43', name: '熊本県', en: 'Kumamoto' },
  { code: '44', name: '大分県', en: 'Oita' },
  { code: '45', name: '宮崎県', en: 'Miyazaki' },
  { code: '46', name: '鹿児島県', en: 'Kagoshima' },
  { code: '47', name: '沖縄県', en: 'Okinawa' },
  { code: '48', name: 'その他', en: 'other' },
]

const areas = [
  {
    code: '01',
    en: 'HOKKAIDO / TOHOKU',
    ja: '北海道・東北',
  },
  {
    code: '02',
    en: 'KANTO',
    ja: '関東',
  },
  {
    code: '03',
    en: 'KOSHINETSU',
    ja: '甲信越',
  },
  {
    code: '04',
    en: 'TOKAI',
    ja: '東海',
  },
  {
    code: '05',
    en: 'KINKI',
    ja: '近畿',
  },
  {
    code: '06',
    en: 'CHUGOKU',
    ja: '中国',
  },
  {
    code: '07',
    en: 'SHIKOKU',
    ja: '四国',
  },
  {
    code: '08',
    en: 'KYUSHU / OKINAWA',
    ja: '九州・沖縄',
  },
]

const prefectureItems = prefs.map((prefecture) => {
  return {
    value: prefecture.code,
    en: prefecture.en,
    ja: prefecture.name,
    'zh-Hans': prefecture.en,
  }
})

const areaItems = areas.map((area) => {
  return {
    value: area.code,
    en: area.en,
    ja: area.ja,
    'zh-Hans': area.en,
  }
})

const enToCodes = (enNames: string[]) => {
  if (enNames.length === 0) {
    return []
  }
  return enNames.map((enName) => {
    const pref = prefs.find((pref) => pref.en === enName)
    return pref ? pref.code : ''
  })
}

const nameToCode = (name: string) => {
  const pref = prefs.find((pref) => pref.name === name)
  return pref ? pref.code : ''
}

const codeToName = (code: string) => {
  const pref = prefs.find((pref) => pref.code === code)
  return pref ? pref.name : ''
}

const codeToEn = (code: string) => {
  const pref = prefs.find((pref) => pref.code === code)
  return pref ? pref.en : ''
}

/* TODO 検索ができるまでリストに表示する都道府県は実質手動 */
const activePrefectureList: RegExp =
  /^(沖縄県|岡山県|山梨県|長野県|秋田県|京都府|北海道|千葉県|新潟県|宮城県|愛媛県|埼玉県|奈良県|静岡県|和歌山県|兵庫県|滋賀県|大阪府|東京都|神奈川県|群馬県|福岡県|茨城県|鹿児島県|徳島県|山形県|島根県|三重県|宮崎県|その他)$/

export {
  areaItems,
  prefectureItems,
  enToCodes,
  nameToCode,
  codeToName,
  codeToEn,
  activePrefectureList,
}
