
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      displayName: '',
      avatar: '',
      introduction: '',
      gender: '',
      occupation: '',
      skills: [],
      prefecture: '',
      birthplace: '',
      user_facebook: '',
      user_twitter: '',
      user_youtube: '',
      user_spotify: '',
      user_github: '',
      user_note: '',
      registeredTime: -1,
      birthDateTime: -1,
    }
  },
  async fetch() {
    const usersDoc = await this.$fire.firestore
      .doc(`users/${this.$route.params.uid}`)
      .get()
      .catch((err) => {
        this.$sentry.captureException(err)
        this.$nuxt.error({ statusCode: 500 })
        return null
      })
    if (!usersDoc) {
      return
    }
    if (!usersDoc.exists) {
      this.$nuxt.error({ statusCode: 404 })
      return
    }
    this.displayName = usersDoc.get('display_name') || ''
    this.avatar = usersDoc.get('avatar') || ''
    this.introduction = usersDoc.get('introduction') || ''
    this.gender = usersDoc.get('gender') || ''
    this.occupation = usersDoc.get('occupation') || ''
    this.skills = usersDoc.get('skills') || []
    this.prefecture = usersDoc.get('prefecture') || ''
    this.birthplace = usersDoc.get('birthplace') || ''
    this.user_facebook = usersDoc.get('user_facebook') || ''
    this.user_twitter = usersDoc.get('user_twitter') || ''
    this.user_youtube = usersDoc.get('user_youtube') || ''
    this.user_spotify = usersDoc.get('user_spotify') || ''
    this.user_github = usersDoc.get('user_github') || ''
    this.user_note = usersDoc.get('user_note') || ''
    this.registeredTime = usersDoc.get('created_at')
      ? usersDoc.get('created_at').toDate().getTime()
      : -1
    this.birthDateTime = usersDoc.get('birth_year')
      ? new Date(
          usersDoc.get('birth_year'),
          usersDoc.get('birth_month') - 1,
          usersDoc.get('birth_day')
        ).getTime()
      : -1

    this.setTitle(this.displayName)
  },
  computed: {
    pageType() {
      if (this.$route.name === 'users-uid-favorites') {
        return 'favorites'
      }
      if (this.$route.name === 'users-uid-map') {
        return 'map'
      }
      return 'logs'
    },
  },
  methods: {
    ...mapActions(['setTitle']),
  },
}
