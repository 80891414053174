
import { activePrefectureList } from '~/prefectures'

export default {
  props: {
    dataListId: {
      type: String,
      default: 'SuggestDataList',
    },
    theme: {
      type: String,
      default: 'dark',
    },
    shrink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let query = ''
    if (Array.isArray(this.$route.query.q)) {
      query = this.$route.query.q[0] ?? ''
    } else {
      query = this.$route.query.q
    }
    return {
      searchFormOpen: false,
      query,
    }
  },
  computed: {
    validQuery() {
      return (
        !!this.query &&
        !/^\s+$/.test(this.query) &&
        this.$route.query.q !== this.query
      )
    },
  },
  watch: {
    $route() {
      this.searchFormOpen = false
    },
    '$route.query.q'(to, from) {
      if (to !== from) {
        this.query = to
      }
    },
  },
  methods: {
    matchOptionFromDatalist() {
      const options = this.$refs.SerchFormInput.list.options
      if (!options) {
        return false
      }
      const option = Array.from(options).find(
        (option) => option.value === this.query
      )
      if (!option) {
        return false
      }
      let query = false
      if (option.dataset.category === 'option') {
        query = `/hotels/options/${option.dataset.sysid}`
      }
      if (option.dataset.category === 'prefecture') {
        query = `/hotels?prefecture=${option.value}`
      }
      return query
    },
    toggleSearchFormOpen() {
      this.searchFormOpen = !this.searchFormOpen
    },
    shuffleSuggest() {
      // @ts-ignore
      this.$refs.suggestDataList?.getRandomSuggest()
    },
    submit() {
      if (this.shrink && !this.searchFormOpen) {
        this.toggleSearchFormOpen()
        return
      }
      if (!this.validQuery) {
        return
      }
      const path = this.matchOptionFromDatalist()
      if (path) {
        this.$router.push({ path })
        return
      }
      if (activePrefectureList.test(this.query.trim())) {
        this.$router.push({
          name: 'hotels',
          query: { prefecture: this.query.trim() },
        })
        return
      }
      this.$router.push({ name: 'hotels', query: { q: this.query } })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
