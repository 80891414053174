
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapGetters({
      breadcrumbList: 'breadcrumbs/list',
    }),
  },
  watch: {
    breadcrumbList() {
      this.list = this.breadcrumbList
    },
  },
}
