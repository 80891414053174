
import { format } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    pageType: {
      type: String,
      default: 'logs',
    },
    avatar: {
      type: String,
      default: '',
    },
    displayName: {
      type: String,
      default: '',
    },
    introduction: {
      type: String,
      default: '',
    },
    gender: {
      type: String,
      default: '',
    },
    occupation: {
      type: String,
      default: '',
    },
    skills: {
      type: Array,
      default: () => [],
    },
    prefecture: {
      type: String,
      default: '',
    },
    birthplace: {
      type: String,
      default: '',
    },
    userFacebook: {
      type: String,
      default: '',
    },
    userTwitter: {
      type: String,
      default: '',
    },
    userYoutube: {
      type: String,
      default: '',
    },
    userSpotify: {
      type: String,
      default: '',
    },
    userGithub: {
      type: String,
      default: '',
    },
    userNote: {
      type: String,
      default: '',
    },
    registeredTime: {
      type: Number,
      default: -1,
    },
    birthDateTime: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      loginUid: 'auth/loginUid',
      occupationsFetching: 'occupations/fetching',
      occupationsDone: 'occupations/done',
      occupationItems: 'occupations/items',
      skillsFetching: 'skills/fetching',
      skillsDone: 'skills/done',
      skillItems: 'skills/items',
    }),
    myPage() {
      return this.loginUid === this.$route.params.uid
    },
    registeredDate() {
      if (this.registeredTime > 0) {
        return format(new Date(this.registeredTime), 'yyyy.MM.dd')
      }
      return ''
    },
    birthDate() {
      if (this.birthDateTime > 0) {
        return format(new Date(this.birthDateTime), 'yyyy年M月d日')
      }
      return '非公開'
    },
    genderStr() {
      if (!this.gender) {
        return '非公開'
      }
      if (this.gender === 'male') {
        return '男性'
      }
      if (this.gender === 'female') {
        return '女性'
      }
      return 'その他'
    },
    occupationName() {
      if (!this.occupation) {
        return '非公開'
      }
      const item = this.occupationItems.find(
        (field) => field.slug === this.occupation
      )
      if (!item) {
        return '非公開'
      }
      return item.name
    },
    skillSet() {
      if (!this.skills || this.skills.length < 1) {
        return []
      }
      const itemSet = []
      this.skills.forEach((skill) => {
        const item = this.skillItems.find(
          (eachSkill) => eachSkill.slug === skill
        )
        if (item) {
          itemSet.push(item)
        }
      })
      return itemSet
    },
  },
  mounted() {
    if (!this.occupationsDone && !this.occupationsFetching) {
      this.fetchOccupations()
    }
    if (!this.skillsDone && !this.skillsFetching) {
      this.fetchSkills()
    }
  },
  methods: {
    ...mapActions({
      fetchOccupations: 'occupations/fetch',
      fetchSkills: 'skills/fetch',
    }),
  },
}
