import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0c452f6e = () => interopDefault(import('../app/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _580fb93f = () => interopDefault(import('../app/pages/area/index.vue' /* webpackChunkName: "pages/area/index" */))
const _1233e041 = () => interopDefault(import('../app/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _1270e49e = () => interopDefault(import('../app/pages/hotels/index.vue' /* webpackChunkName: "pages/hotels/index" */))
const _3903f406 = () => interopDefault(import('../app/pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _938457f6 = () => interopDefault(import('../app/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _54a0f5bd = () => interopDefault(import('../app/pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _70f85d6d = () => interopDefault(import('../app/pages/reservation.vue' /* webpackChunkName: "pages/reservation" */))
const _74c4ccd6 = () => interopDefault(import('../app/pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _ed49b968 = () => interopDefault(import('../app/pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _51493946 = () => interopDefault(import('../app/pages/special/index.vue' /* webpackChunkName: "pages/special/index" */))
const _29baa390 = () => interopDefault(import('../app/pages/thanks.vue' /* webpackChunkName: "pages/thanks" */))
const _6ad15df9 = () => interopDefault(import('../app/pages/workspace/index.vue' /* webpackChunkName: "pages/workspace/index" */))
const _0e49aabe = () => interopDefault(import('../app/pages/terms/covid.vue' /* webpackChunkName: "pages/terms/covid" */))
const _c6f6145a = () => interopDefault(import('../app/pages/terms/privacypolicy.vue' /* webpackChunkName: "pages/terms/privacypolicy" */))
const _635e4e63 = () => interopDefault(import('../app/pages/terms/term.vue' /* webpackChunkName: "pages/terms/term" */))
const _2bfd0a33 = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _14d49a10 = () => interopDefault(import('../app/pages/hotels/categories/_id.vue' /* webpackChunkName: "pages/hotels/categories/_id" */))
const _0afcd230 = () => interopDefault(import('../app/pages/hotels/options/_id.vue' /* webpackChunkName: "pages/hotels/options/_id" */))
const _2786fab5 = () => interopDefault(import('../app/pages/hotels/tags/_id.vue' /* webpackChunkName: "pages/hotels/tags/_id" */))
const _5362c77a = () => interopDefault(import('../app/pages/magazine/categories/_id.vue' /* webpackChunkName: "pages/magazine/categories/_id" */))
const _1a4f7840 = () => interopDefault(import('../app/pages/magazine/tags/_id.vue' /* webpackChunkName: "pages/magazine/tags/_id" */))
const _2e79a6e4 = () => interopDefault(import('../app/pages/project/category/_id.vue' /* webpackChunkName: "pages/project/category/_id" */))
const _07cf8045 = () => interopDefault(import('../app/pages/project/option/_id.vue' /* webpackChunkName: "pages/project/option/_id" */))
const _74b01130 = () => interopDefault(import('../app/pages/workspace/options/_id.vue' /* webpackChunkName: "pages/workspace/options/_id" */))
const _1e68ff67 = () => interopDefault(import('../app/pages/area/_id.vue' /* webpackChunkName: "pages/area/_id" */))
const _24e16f4e = () => interopDefault(import('../app/pages/hotels/_id.vue' /* webpackChunkName: "pages/hotels/_id" */))
const _16eb3fee = () => interopDefault(import('../app/pages/magazine/_id.vue' /* webpackChunkName: "pages/magazine/_id" */))
const _e669d8a6 = () => interopDefault(import('../app/pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _53097936 = () => interopDefault(import('../app/pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _62349905 = () => interopDefault(import('../app/pages/special/_id.vue' /* webpackChunkName: "pages/special/_id" */))
const _32530cea = () => interopDefault(import('../app/pages/users/_uid/index.vue' /* webpackChunkName: "pages/users/_uid/index" */))
const _1e3eeabe = () => interopDefault(import('../app/pages/workspace/_id.vue' /* webpackChunkName: "pages/workspace/_id" */))
const _11825dbc = () => interopDefault(import('../app/pages/users/_uid/edit.vue' /* webpackChunkName: "pages/users/_uid/edit" */))
const _4d3fc3ef = () => interopDefault(import('../app/pages/users/_uid/favorites.vue' /* webpackChunkName: "pages/users/_uid/favorites" */))
const _cc665718 = () => interopDefault(import('../app/pages/users/_uid/map.vue' /* webpackChunkName: "pages/users/_uid/map" */))
const _139504ea = () => interopDefault(import('../app/pages/users/_uid/email-certify/_id.vue' /* webpackChunkName: "pages/users/_uid/email-certify/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0c452f6e,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/area",
    component: _580fb93f,
    pathToRegexpOptions: {"strict":true},
    name: "area"
  }, {
    path: "/contact",
    component: _1233e041,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/hotels",
    component: _1270e49e,
    pathToRegexpOptions: {"strict":true},
    name: "hotels"
  }, {
    path: "/magazine",
    component: _3903f406,
    pathToRegexpOptions: {"strict":true},
    name: "magazine"
  }, {
    path: "/news",
    component: _938457f6,
    pathToRegexpOptions: {"strict":true},
    name: "news"
  }, {
    path: "/project",
    component: _54a0f5bd,
    pathToRegexpOptions: {"strict":true},
    name: "project"
  }, {
    path: "/reservation",
    component: _70f85d6d,
    pathToRegexpOptions: {"strict":true},
    name: "reservation"
  }, {
    path: "/sign-in",
    component: _74c4ccd6,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _ed49b968,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up"
  }, {
    path: "/special",
    component: _51493946,
    pathToRegexpOptions: {"strict":true},
    name: "special"
  }, {
    path: "/thanks",
    component: _29baa390,
    pathToRegexpOptions: {"strict":true},
    name: "thanks"
  }, {
    path: "/workspace",
    component: _6ad15df9,
    pathToRegexpOptions: {"strict":true},
    name: "workspace"
  }, {
    path: "/terms/covid",
    component: _0e49aabe,
    pathToRegexpOptions: {"strict":true},
    name: "terms-covid"
  }, {
    path: "/terms/privacypolicy",
    component: _c6f6145a,
    pathToRegexpOptions: {"strict":true},
    name: "terms-privacypolicy"
  }, {
    path: "/terms/term",
    component: _635e4e63,
    pathToRegexpOptions: {"strict":true},
    name: "terms-term"
  }, {
    path: "/",
    component: _2bfd0a33,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/hotels/categories/:id?",
    component: _14d49a10,
    pathToRegexpOptions: {"strict":true},
    name: "hotels-categories-id"
  }, {
    path: "/hotels/options/:id?",
    component: _0afcd230,
    pathToRegexpOptions: {"strict":true},
    name: "hotels-options-id"
  }, {
    path: "/hotels/tags/:id?",
    component: _2786fab5,
    pathToRegexpOptions: {"strict":true},
    name: "hotels-tags-id"
  }, {
    path: "/magazine/categories/:id?",
    component: _5362c77a,
    pathToRegexpOptions: {"strict":true},
    name: "magazine-categories-id"
  }, {
    path: "/magazine/tags/:id?",
    component: _1a4f7840,
    pathToRegexpOptions: {"strict":true},
    name: "magazine-tags-id"
  }, {
    path: "/project/category/:id?",
    component: _2e79a6e4,
    pathToRegexpOptions: {"strict":true},
    name: "project-category-id"
  }, {
    path: "/project/option/:id?",
    component: _07cf8045,
    pathToRegexpOptions: {"strict":true},
    name: "project-option-id"
  }, {
    path: "/workspace/options/:id?",
    component: _74b01130,
    pathToRegexpOptions: {"strict":true},
    name: "workspace-options-id"
  }, {
    path: "/area/:id",
    component: _1e68ff67,
    pathToRegexpOptions: {"strict":true},
    name: "area-id"
  }, {
    path: "/hotels/:id",
    component: _24e16f4e,
    pathToRegexpOptions: {"strict":true},
    name: "hotels-id"
  }, {
    path: "/magazine/:id",
    component: _16eb3fee,
    pathToRegexpOptions: {"strict":true},
    name: "magazine-id"
  }, {
    path: "/news/:id",
    component: _e669d8a6,
    pathToRegexpOptions: {"strict":true},
    name: "news-id"
  }, {
    path: "/project/:id",
    component: _53097936,
    pathToRegexpOptions: {"strict":true},
    name: "project-id"
  }, {
    path: "/special/:id",
    component: _62349905,
    pathToRegexpOptions: {"strict":true},
    name: "special-id"
  }, {
    path: "/users/:uid",
    component: _32530cea,
    pathToRegexpOptions: {"strict":true},
    name: "users-uid"
  }, {
    path: "/workspace/:id",
    component: _1e3eeabe,
    pathToRegexpOptions: {"strict":true},
    name: "workspace-id"
  }, {
    path: "/users/:uid?/edit",
    component: _11825dbc,
    pathToRegexpOptions: {"strict":true},
    name: "users-uid-edit"
  }, {
    path: "/users/:uid?/favorites",
    component: _4d3fc3ef,
    pathToRegexpOptions: {"strict":true},
    name: "users-uid-favorites"
  }, {
    path: "/users/:uid?/map",
    component: _cc665718,
    pathToRegexpOptions: {"strict":true},
    name: "users-uid-map"
  }, {
    path: "/users/:uid?/email-certify/:id?",
    component: _139504ea,
    pathToRegexpOptions: {"strict":true},
    name: "users-uid-email-certify-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
