import type { ActionTree, MutationTree } from 'vuex'

export const state = () => ({
  modalCodeFlag: false,
  modalExternalFlag: false,
  modalAddLogFlag: false,
  pageTitle: '',
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  // TODO 見直し
  setModalFlag(state, val: false | { type: 'code' | 'share' | 'addLog' }) {
    if (!val) {
      state.modalCodeFlag = false
      state.modalExternalFlag = false
      state.modalAddLogFlag = false
    } else if (val.type === 'code') {
      state.modalCodeFlag = true
    } else if (val.type === 'share') {
      state.modalExternalFlag = true
    } else if (val.type === 'addLog') {
      state.modalAddLogFlag = true
    } else {
      state.modalCodeFlag = false
      state.modalExternalFlag = false
      state.modalAddLogFlag = false
    }
  },
  setTitle(state, val) {
    state.pageTitle = val
  },
}

export const actions: ActionTree<RootState, RootState> = {
  modal(
    { commit },
    payload: { name: 'string'; type: 'code' | 'share' | 'addLog' }
  ) {
    commit(payload.name, payload)
  },
  setTitle({ commit }, payload: string) {
    commit('setTitle', payload)
  },
}
