
import { activePrefectureList } from '~/prefectures'

export default {
  data() {
    const suggestPopularOptions = []
    const suggestPrefectures = []
    return {
      suggestPopularOptions,
      suggestPrefectures,
    }
  },
  watch: {
    $route() {
      this.getRandomSuggest()
    },
  },
  async mounted() {
    if (
      !this.$store.getters['options/done'] &&
      !this.$store.getters['options/fetching']
    ) {
      await this.$store.dispatch('options/fetch')
    }
    if (
      !this.$store.getters['prefectures/done'] &&
      !this.$store.getters['prefectures/fetching']
    ) {
      await this.$store.dispatch('prefectures/fetch')
    }
    this.getRandomSuggest()
  },
  methods: {
    getRandomSuggest() {
      this.suggestPopularOptions = [
        ...this.$store.getters['options/items'].filter(
          (option) => option.fields.popular === true
        ),
      ]
        .sort(() => Math.random() - 0.5)
        .slice(0, 2)

      this.suggestPrefectures = [
        ...this.$store.getters['prefectures/items'].filter((prefecture) =>
          activePrefectureList.test(prefecture.fields.name)
        ),
      ]
        .sort(() => Math.random() - 0.5)
        .slice(0, 8)
    },
  },
}
