
export default {
  data() {
    return {
      loading: true,
      latestNews: null,
    }
  },
  mounted() {
    this.$contentful
      .getEntries({
        locale: this.$i18n.locale,
        content_type: 'news',
        order: '-fields.publishDate',
        'fields.title[exists]': true,
        limit: 1,
        select:
          'sys.id,fields.thumbnail,fields.title,fields.publishDate,fields.category',
      })
      .then((result) => {
        if (result.items.length > 0) {
          this.latestNews = result.items[0]
        }
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  },
}
