const middleware = {}

middleware['authenticated'] = require('../app/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['isEmailVerified'] = require('../app/middleware/isEmailVerified.ts')
middleware['isEmailVerified'] = middleware['isEmailVerified'].default || middleware['isEmailVerified']

middleware['no-slash'] = require('../app/middleware/no-slash.ts')
middleware['no-slash'] = middleware['no-slash'].default || middleware['no-slash']

middleware['registered'] = require('../app/middleware/registered.ts')
middleware['registered'] = middleware['registered'].default || middleware['registered']

export default middleware
