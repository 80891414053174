import type { Middleware } from '@nuxt/types'

const authenticated: Middleware = ({ route, store, redirect }) => {
  if (!store.getters['auth/isAuthenticated']) {
    if (route.name === 'index' || route.name === 'sign-in') {
      redirect(`/sign-in`)
    } else {
      redirect(`/sign-in`, { redirect: route.fullPath })
    }
  }
}

export default authenticated
