import type { Middleware } from '@nuxt/types'

const registered: Middleware = ({ store, redirect, route }) => {
  if (!process.client) {
    return
  }
  if (
    route.name === 'sign-up' &&
    store.getters['auth/isAuthenticated'] &&
    store.getters['auth/isRegistered']
  ) {
    redirect(`/users/${store.getters['auth/loginUid']}`)
  } else if (
    route.name !== 'sign-up' &&
    store.getters['auth/isAuthenticated'] &&
    !store.getters['auth/isRegistered']
  ) {
    if (route.name === 'index') {
      redirect(`/sign-up`)
    } else {
      redirect(`/sign-up`, { redirect: route.fullPath })
    }
  }
}

export default registered
