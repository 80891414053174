import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import type { Entry } from 'contentful'
import type { RootState } from '~/store'

export const state = () => ({
  items: [] as Entry<Workations.SkillFields>[],
  fetching: false,
  done: false,
})

export type SkillsState = ReturnType<typeof state>

export const getters: GetterTree<SkillsState, RootState> = {
  items: (state) => state.items,
  fetching: (state) => state.fetching,
  done: (state) => state.done,
}

export const mutations: MutationTree<SkillsState> = {
  SET_ITEMS(state, items: Entry<Workations.SkillFields>[]) {
    state.items = items
    state.fetching = false
    state.done = true
  },
  FETCHING(state) {
    state.fetching = true
  },
}

export const actions: ActionTree<SkillsState, RootState> = {
  async fetch({ commit }) {
    commit('FETCHING')
    const result = await this.$contentful
      .getEntries<Workations.SkillFields>({
        locale: this.$i18n.locale,
        content_type: 'skill',
        'fields.name[exists]': true,
        limit: 100,
        select: 'sys.id,fields.name,fields.slug',
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        return null
      })
    if (result) {
      commit(
        'SET_ITEMS',
        result.items.map((item) => {
          return {
            slug: item.fields.slug,
            name: item.fields.name,
          }
        })
      )
    }
  },
}
