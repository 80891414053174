import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F965722afa55d43e7a334664f5ba2570d@o265538.ingest.sentry.io\u002F5393195",
    environment:"production",
    ignoreErrors:["top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http:\u002F\u002Ftt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http:\u002F\u002Floading.retry.widdit.com\u002F","atomicFindClose","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage","ReportingObserver [deprecation]:","Network request failed","Network Error","NetworkError","Failed to get document because the client is offline.","The resource could not be found.",new RegExp("Loading chunk \\d+ failed\\.", "")],
    denyUrls:[new RegExp("graph\\.facebook\\.com", "i"),new RegExp("connect\\.facebook\\.net\\\u002Fen_US\\\u002Fall\\.js", "i"),new RegExp("eatdifferent\\.com\\.woopra-ns\\.com", "i"),new RegExp("static\\.woopra\\.com\\\u002Fjs\\\u002Fwoopra\\.js", "i"),new RegExp("extensions\\\u002F", "i"),new RegExp("^chrome:\\\u002F\\\u002F", "i"),new RegExp("127\\.0\\.0\\.1:4001\\\u002Fisrunning", "i"),new RegExp("webappstoolbarba\\.texthelp\\.com\\\u002F", "i"),new RegExp("metrics\\.itunes\\.apple\\.com\\.edgesuite\\.net\\\u002F", "i")],
    release:"203ecb816de3658408ef1a261fa590ed0671dcc3"
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
