import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import type { Entry } from 'contentful'
import type { RootState } from '~/store'

export const state = () => ({
  items: [] as Entry<Workations.HotelCategoryFields>[],
  fetching: false,
  done: false,
})

export type CategoriesState = ReturnType<typeof state>

export const getters: GetterTree<CategoriesState, RootState> = {
  items: (state) => state.items,
  fetching: (state) => state.fetching,
  done: (state) => state.done,
}

export const mutations: MutationTree<CategoriesState> = {
  SET_ITEMS(state, items: Entry<Workations.HotelCategoryFields>[]) {
    state.items = items
    state.fetching = false
    state.done = true
  },
  FETCHING(state) {
    state.fetching = true
  },
}

export const actions: ActionTree<CategoriesState, RootState> = {
  async fetch({ commit }) {
    commit('FETCHING')
    const result = await this.$contentful
      .getEntries<Workations.HotelCategoryFields>({
        locale: this.$i18n.locale,
        content_type: 'hotelCategory',
        'fields.name[exists]': true,
        select: 'sys.id,sys.contentType,fields.name,fields.thumbnail',
        order: 'fields.sort',
        limit: 50,
      })
      .catch((error) => {
        this.$sentry.captureException(error)
        return { items: [] }
      })
    commit('SET_ITEMS', result.items)
  },
}
