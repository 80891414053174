import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=2bb48ff5&scoped=true&"
import script from "./Search.vue?vue&type=script&lang=js&"
export * from "./Search.vue?vue&type=script&lang=js&"
import style0 from "./Search.vue?vue&type=style&index=0&id=2bb48ff5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bb48ff5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/home/runner/work/workation-web/workation-web/app/components/common/Input.vue').default,SearchSuggest: require('/home/runner/work/workation-web/workation-web/app/components/common/SearchSuggest.vue').default,IconLoupe: require('/home/runner/work/workation-web/workation-web/app/components/figure/IconLoupe.vue').default,Form: require('/home/runner/work/workation-web/workation-web/app/components/common/Form.vue').default})
