
export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
    small: {
      type: String,
      default: '',
    },
  },
}
