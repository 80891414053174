
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      menuState: 'menu/state',
    }),
  },
  methods: {
    async menuClose() {
      clearAllBodyScrollLocks()
      await this.$store.dispatch('menu/set', false)
    },
  },
}
