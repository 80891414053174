import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import type { RootState } from '~/store'
import Workations from '~~/types/workations'

export const state = () => ({
  list: [
    {
      name: 'TOP',
      to: '/',
    },
  ],
})

export type StateType = { list: Workations.BreadCrumb[] }

export const getters: GetterTree<StateType, RootState> = {
  list: ({ list }) => list,
}

export const mutations: MutationTree<StateType> = {
  SET(state, val) {
    state.list = val
  },
}

export const actions: ActionTree<StateType, RootState> = {
  set({ commit }, state) {
    commit('SET', state)
  },
}
