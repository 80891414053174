var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"Footer"},[_c('nav',{staticClass:"Links"},[_c('nuxt-link',{staticClass:"Links__Item",attrs:{"to":"/special"},nativeOn:{"click":function($event){return _vm.$gtag('event', 'research', {
          event_category: 'click',
          event_label: 'header_menu_special',
        })}}},[_vm._v("\n      Special\n    ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"Links__Item",attrs:{"to":"/area"},nativeOn:{"click":function($event){return _vm.$gtag('event', 'research', {
          event_category: 'click',
          event_label: 'header_menu_area',
        })}}},[_vm._v("\n      Area\n    ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"Links__Item",attrs:{"to":"/project"},nativeOn:{"click":function($event){return _vm.$gtag('event', 'research', {
          event_category: 'click',
          event_label: 'header_menu_project',
        })}}},[_vm._v("\n      Project\n    ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"Links__Item",attrs:{"to":"/magazine"},nativeOn:{"click":function($event){return _vm.$gtag('event', 'research', {
          event_category: 'click',
          event_label: 'header_menu_magazine',
        })}}},[_vm._v("\n      Magazine\n    ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"Links__Item",attrs:{"to":"/hotels"},nativeOn:{"click":function($event){return _vm.$gtag('event', 'research', {
          event_category: 'click',
          event_label: 'header_menu_hotels',
        })}}},[_vm._v("\n      Hotel\n    ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"Links__Item",attrs:{"to":"/workspace"},nativeOn:{"click":function($event){return _vm.$gtag('event', 'research', {
          event_category: 'click',
          event_label: 'header_menu_workspace',
        })}}},[_vm._v("\n      Workspace\n    ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"Links__Item",attrs:{"to":"/news"},nativeOn:{"click":function($event){return _vm.$gtag('event', 'research', {
          event_category: 'click',
          event_label: 'header_menu_news',
        })}}},[_vm._v("\n      News\n    ")]),_vm._v(" "),_c('a',{staticClass:"Links__Item",attrs:{"href":"https://spot.peeeps.app/ja/","target":"_blank"}},[_vm._v("GUIDEBOOK"),_c('IconLink',{attrs:{"fill":"var(--white)"}})],1)],1),_vm._v(" "),_c('Nav')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }