export const IconAccount = () => import('../../app/components/figure/IconAccount.vue' /* webpackChunkName: "components/icon-account" */).then(c => wrapFunctional(c.default || c))
export const IconBlank = () => import('../../app/components/figure/IconBlank.vue' /* webpackChunkName: "components/icon-blank" */).then(c => wrapFunctional(c.default || c))
export const IconBriefcase = () => import('../../app/components/figure/IconBriefcase.vue' /* webpackChunkName: "components/icon-briefcase" */).then(c => wrapFunctional(c.default || c))
export const IconCake = () => import('../../app/components/figure/IconCake.vue' /* webpackChunkName: "components/icon-cake" */).then(c => wrapFunctional(c.default || c))
export const IconCheckCircle = () => import('../../app/components/figure/IconCheckCircle.vue' /* webpackChunkName: "components/icon-check-circle" */).then(c => wrapFunctional(c.default || c))
export const IconCheckbox = () => import('../../app/components/figure/IconCheckbox.vue' /* webpackChunkName: "components/icon-checkbox" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRight = () => import('../../app/components/figure/IconChevronRight.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRightBox = () => import('../../app/components/figure/IconChevronRightBox.vue' /* webpackChunkName: "components/icon-chevron-right-box" */).then(c => wrapFunctional(c.default || c))
export const IconFacebook = () => import('../../app/components/figure/IconFacebook.vue' /* webpackChunkName: "components/icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconGender = () => import('../../app/components/figure/IconGender.vue' /* webpackChunkName: "components/icon-gender" */).then(c => wrapFunctional(c.default || c))
export const IconGithub = () => import('../../app/components/figure/IconGithub.vue' /* webpackChunkName: "components/icon-github" */).then(c => wrapFunctional(c.default || c))
export const IconHeart = () => import('../../app/components/figure/IconHeart.vue' /* webpackChunkName: "components/icon-heart" */).then(c => wrapFunctional(c.default || c))
export const IconImage = () => import('../../app/components/figure/IconImage.vue' /* webpackChunkName: "components/icon-image" */).then(c => wrapFunctional(c.default || c))
export const IconInstagram = () => import('../../app/components/figure/IconInstagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconLine = () => import('../../app/components/figure/IconLine.vue' /* webpackChunkName: "components/icon-line" */).then(c => wrapFunctional(c.default || c))
export const IconLink = () => import('../../app/components/figure/IconLink.vue' /* webpackChunkName: "components/icon-link" */).then(c => wrapFunctional(c.default || c))
export const IconLoupe = () => import('../../app/components/figure/IconLoupe.vue' /* webpackChunkName: "components/icon-loupe" */).then(c => wrapFunctional(c.default || c))
export const IconMap = () => import('../../app/components/figure/IconMap.vue' /* webpackChunkName: "components/icon-map" */).then(c => wrapFunctional(c.default || c))
export const IconNote = () => import('../../app/components/figure/IconNote.vue' /* webpackChunkName: "components/icon-note" */).then(c => wrapFunctional(c.default || c))
export const IconPin = () => import('../../app/components/figure/IconPin.vue' /* webpackChunkName: "components/icon-pin" */).then(c => wrapFunctional(c.default || c))
export const IconSpotify = () => import('../../app/components/figure/IconSpotify.vue' /* webpackChunkName: "components/icon-spotify" */).then(c => wrapFunctional(c.default || c))
export const IconTwitter = () => import('../../app/components/figure/IconTwitter.vue' /* webpackChunkName: "components/icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconYoutube = () => import('../../app/components/figure/IconYoutube.vue' /* webpackChunkName: "components/icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../app/components/figure/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const AreaCard = () => import('../../app/components/common/AreaCard.vue' /* webpackChunkName: "components/area-card" */).then(c => wrapFunctional(c.default || c))
export const AttrLabel = () => import('../../app/components/common/AttrLabel.vue' /* webpackChunkName: "components/attr-label" */).then(c => wrapFunctional(c.default || c))
export const AvatarUpdater = () => import('../../app/components/common/AvatarUpdater.vue' /* webpackChunkName: "components/avatar-updater" */).then(c => wrapFunctional(c.default || c))
export const BackgroundGray = () => import('../../app/components/common/BackgroundGray.vue' /* webpackChunkName: "components/background-gray" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../app/components/common/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const ChangeLocale = () => import('../../app/components/common/ChangeLocale.vue' /* webpackChunkName: "components/change-locale" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../app/components/common/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const Date = () => import('../../app/components/common/Date.vue' /* webpackChunkName: "components/date" */).then(c => wrapFunctional(c.default || c))
export const Detail = () => import('../../app/components/common/Detail.vue' /* webpackChunkName: "components/detail" */).then(c => wrapFunctional(c.default || c))
export const DummyCard = () => import('../../app/components/common/DummyCard.vue' /* webpackChunkName: "components/dummy-card" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../app/components/common/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../app/components/common/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const FormError = () => import('../../app/components/common/FormError.vue' /* webpackChunkName: "components/form-error" */).then(c => wrapFunctional(c.default || c))
export const FormGrandItem = () => import('../../app/components/common/FormGrandItem.vue' /* webpackChunkName: "components/form-grand-item" */).then(c => wrapFunctional(c.default || c))
export const FormItem = () => import('../../app/components/common/FormItem.vue' /* webpackChunkName: "components/form-item" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../app/components/common/GoogleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../app/components/common/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Html = () => import('../../app/components/common/Html.vue' /* webpackChunkName: "components/html" */).then(c => wrapFunctional(c.default || c))
export const InformationBoard = () => import('../../app/components/common/InformationBoard.vue' /* webpackChunkName: "components/information-board" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../app/components/common/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const Links = () => import('../../app/components/common/Links.vue' /* webpackChunkName: "components/links" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../app/components/common/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LoadingNotFound = () => import('../../app/components/common/LoadingNotFound.vue' /* webpackChunkName: "components/loading-not-found" */).then(c => wrapFunctional(c.default || c))
export const Main = () => import('../../app/components/common/Main.vue' /* webpackChunkName: "components/main" */).then(c => wrapFunctional(c.default || c))
export const Membership = () => import('../../app/components/common/Membership.vue' /* webpackChunkName: "components/membership" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../app/components/common/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const Nav = () => import('../../app/components/common/Nav.vue' /* webpackChunkName: "components/nav" */).then(c => wrapFunctional(c.default || c))
export const Option = () => import('../../app/components/common/Option.vue' /* webpackChunkName: "components/option" */).then(c => wrapFunctional(c.default || c))
export const PageFilter = () => import('../../app/components/common/PageFilter.vue' /* webpackChunkName: "components/page-filter" */).then(c => wrapFunctional(c.default || c))
export const PageHeading = () => import('../../app/components/common/PageHeading.vue' /* webpackChunkName: "components/page-heading" */).then(c => wrapFunctional(c.default || c))
export const PageSearch = () => import('../../app/components/common/PageSearch.vue' /* webpackChunkName: "components/page-search" */).then(c => wrapFunctional(c.default || c))
export const PerimeterHotel = () => import('../../app/components/common/PerimeterHotel.vue' /* webpackChunkName: "components/perimeter-hotel" */).then(c => wrapFunctional(c.default || c))
export const PerimeterWorkspace = () => import('../../app/components/common/PerimeterWorkspace.vue' /* webpackChunkName: "components/perimeter-workspace" */).then(c => wrapFunctional(c.default || c))
export const Pickups = () => import('../../app/components/common/Pickups.vue' /* webpackChunkName: "components/pickups" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../app/components/common/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchSuggest = () => import('../../app/components/common/SearchSuggest.vue' /* webpackChunkName: "components/search-suggest" */).then(c => wrapFunctional(c.default || c))
export const SectionHeading = () => import('../../app/components/common/SectionHeading.vue' /* webpackChunkName: "components/section-heading" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../app/components/common/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../app/components/common/Share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const Side = () => import('../../app/components/common/Side.vue' /* webpackChunkName: "components/side" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../app/components/common/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const SliderItem = () => import('../../app/components/common/SliderItem.vue' /* webpackChunkName: "components/slider-item" */).then(c => wrapFunctional(c.default || c))
export const Terms = () => import('../../app/components/common/Terms.vue' /* webpackChunkName: "components/terms" */).then(c => wrapFunctional(c.default || c))
export const Textarea = () => import('../../app/components/common/Textarea.vue' /* webpackChunkName: "components/textarea" */).then(c => wrapFunctional(c.default || c))
export const AreaDetailSection = () => import('../../app/components/pages/area/AreaDetailSection.vue' /* webpackChunkName: "components/area-detail-section" */).then(c => wrapFunctional(c.default || c))
export const AreaLayout = () => import('../../app/components/pages/area/AreaLayout.vue' /* webpackChunkName: "components/area-layout" */).then(c => wrapFunctional(c.default || c))
export const AreaSection = () => import('../../app/components/pages/area/AreaSection.vue' /* webpackChunkName: "components/area-section" */).then(c => wrapFunctional(c.default || c))
export const GuidebookCard = () => import('../../app/components/pages/guidebook/GuidebookCard.vue' /* webpackChunkName: "components/guidebook-card" */).then(c => wrapFunctional(c.default || c))
export const GuidebookList = () => import('../../app/components/pages/guidebook/GuidebookList.vue' /* webpackChunkName: "components/guidebook-list" */).then(c => wrapFunctional(c.default || c))
export const HotelCard = () => import('../../app/components/pages/hotel/HotelCard.vue' /* webpackChunkName: "components/hotel-card" */).then(c => wrapFunctional(c.default || c))
export const HotelCardList = () => import('../../app/components/pages/hotel/HotelCardList.vue' /* webpackChunkName: "components/hotel-card-list" */).then(c => wrapFunctional(c.default || c))
export const HotelDetailHead = () => import('../../app/components/pages/hotel/HotelDetailHead.vue' /* webpackChunkName: "components/hotel-detail-head" */).then(c => wrapFunctional(c.default || c))
export const HotelDetailImages = () => import('../../app/components/pages/hotel/HotelDetailImages.vue' /* webpackChunkName: "components/hotel-detail-images" */).then(c => wrapFunctional(c.default || c))
export const HotelDetailSide = () => import('../../app/components/pages/hotel/HotelDetailSide.vue' /* webpackChunkName: "components/hotel-detail-side" */).then(c => wrapFunctional(c.default || c))
export const HotelDetailSideButtons = () => import('../../app/components/pages/hotel/HotelDetailSideButtons.vue' /* webpackChunkName: "components/hotel-detail-side-buttons" */).then(c => wrapFunctional(c.default || c))
export const HotelDetailSubmit = () => import('../../app/components/pages/hotel/HotelDetailSubmit.vue' /* webpackChunkName: "components/hotel-detail-submit" */).then(c => wrapFunctional(c.default || c))
export const HotelPlanCard = () => import('../../app/components/pages/hotel/HotelPlanCard.vue' /* webpackChunkName: "components/hotel-plan-card" */).then(c => wrapFunctional(c.default || c))
export const HotelSection = () => import('../../app/components/pages/hotel/HotelSection.vue' /* webpackChunkName: "components/hotel-section" */).then(c => wrapFunctional(c.default || c))
export const MagazineCard = () => import('../../app/components/pages/magazine/MagazineCard.vue' /* webpackChunkName: "components/magazine-card" */).then(c => wrapFunctional(c.default || c))
export const MagazineCardList = () => import('../../app/components/pages/magazine/MagazineCardList.vue' /* webpackChunkName: "components/magazine-card-list" */).then(c => wrapFunctional(c.default || c))
export const MagazineSection = () => import('../../app/components/pages/magazine/MagazineSection.vue' /* webpackChunkName: "components/magazine-section" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../app/components/pages/news/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectAlert = () => import('../../app/components/pages/project/ProjectAlert.vue' /* webpackChunkName: "components/project-alert" */).then(c => wrapFunctional(c.default || c))
export const ProjectCard = () => import('../../app/components/pages/project/ProjectCard.vue' /* webpackChunkName: "components/project-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectSection = () => import('../../app/components/pages/project/ProjectSection.vue' /* webpackChunkName: "components/project-section" */).then(c => wrapFunctional(c.default || c))
export const ReservationForm = () => import('../../app/components/pages/reservation/ReservationForm.vue' /* webpackChunkName: "components/reservation-form" */).then(c => wrapFunctional(c.default || c))
export const SpecialCard = () => import('../../app/components/pages/special/SpecialCard.vue' /* webpackChunkName: "components/special-card" */).then(c => wrapFunctional(c.default || c))
export const SpecialCardLink = () => import('../../app/components/pages/special/SpecialCardLink.vue' /* webpackChunkName: "components/special-card-link" */).then(c => wrapFunctional(c.default || c))
export const TopSection = () => import('../../app/components/pages/top/TopSection.vue' /* webpackChunkName: "components/top-section" */).then(c => wrapFunctional(c.default || c))
export const UsersEmpty = () => import('../../app/components/pages/users/UsersEmpty.vue' /* webpackChunkName: "components/users-empty" */).then(c => wrapFunctional(c.default || c))
export const UsersHead = () => import('../../app/components/pages/users/UsersHead.vue' /* webpackChunkName: "components/users-head" */).then(c => wrapFunctional(c.default || c))
export const UsersHeadLinks = () => import('../../app/components/pages/users/UsersHeadLinks.vue' /* webpackChunkName: "components/users-head-links" */).then(c => wrapFunctional(c.default || c))
export const UsersMap = () => import('../../app/components/pages/users/UsersMap.vue' /* webpackChunkName: "components/users-map" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceAlert = () => import('../../app/components/pages/workspace/WorkspaceAlert.vue' /* webpackChunkName: "components/workspace-alert" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceCard = () => import('../../app/components/pages/workspace/WorkspaceCard.vue' /* webpackChunkName: "components/workspace-card" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceCardList = () => import('../../app/components/pages/workspace/WorkspaceCardList.vue' /* webpackChunkName: "components/workspace-card-list" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceSection = () => import('../../app/components/pages/workspace/WorkspaceSection.vue' /* webpackChunkName: "components/workspace-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
