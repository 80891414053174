
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
export default {
  watch: {
    $route: function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
}
