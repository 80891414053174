import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=15346a0e&scoped=true&"
var script = {}
import style0 from "./Main.vue?vue&type=style&index=0&id=15346a0e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15346a0e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Main: require('/home/runner/work/workation-web/workation-web/app/components/common/Main.vue').default})
