
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    social: {
      type: Boolean,
      default: false,
    },
    keywords: {
      type: Boolean,
      default: false,
    },
    music: {
      type: Boolean,
      default: false,
    },
    banner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bannerItems: [],
    }
  },
  computed: {
    ...mapGetters({
      optionsFetching: 'options/fetching',
      optionsDone: 'options/done',
      options: 'options/items',
    }),
    randomOptions() {
      const arr = [...this.options].sort(() => Math.random() - 0.5)
      return arr.slice(0, 8)
    },
  },
  async mounted() {
    if (!this.optionsDone && !this.optionsFetching) {
      this.optionsFetch()
    }
    if (this.bannerItems.length <= 0) {
      this.bannerItems = await this.fetchBanner()
    }
  },
  methods: {
    ...mapActions({
      optionsFetch: 'options/fetch',
    }),
    async fetchBanner() {
      const result = await this.$contentful
        .getEntries({
          locale: this.$i18n.locale,
          content_type: 'banner',
          'fields.alt[exists]': true,
          order: '-fields.publishDate',
          limit: 4,
          select: 'sys.id,fields.alt,fields.image,fields.href',
        })
        .then((res) =>
          res.items.map((item) => {
            return {
              alt: item.fields.alt,
              image: item.fields.image.fields.file.url,
              href: item.fields.href,
            }
          })
        )
        .catch((error) => {
          this.$sentry.captureException(error)
          return []
        })
      return result
    },
  },
}
