import { createClient } from 'contentful'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import type { Plugin } from '@nuxt/types'
import type { ContentfulClientApi } from 'contentful'
import type { Document } from '@contentful/rich-text-types'
import type { Options } from '@contentful/rich-text-html-renderer'

declare module 'vue/types/vue' {
  // this.$contentful inside Vue components
  interface Vue {
    $contentful: ContentfulClientApi
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$contentful inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $contentful: ContentfulClientApi
  }
  // nuxtContext.$contentful
  interface Context {
    $contentful: ContentfulClientApi
  }
}

declare module 'vuex/types/index' {
  // this.$contentful inside Vuex stores
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $contentful: ContentfulClientApi
  }
}

const config =
  process.env.STAGING === '1'
    ? {
        space: process.env.CTF_SPACE_ID || '',
        accessToken: process.env.CTF_ACCESS_TOKEN || '',
        host: 'preview.contentful.com',
      }
    : {
        space: process.env.CTF_SPACE_ID || '',
        accessToken: process.env.CTF_ACCESS_TOKEN || '',
      }

const contentfulPlugin: Plugin = (_, inject) => {
  inject('contentful', createClient(config))
  inject('contentfulRenderer', {
    toHtmlString(obj: Document) {
      const options: Options = {
        renderNode: {
          [INLINES.HYPERLINK]: (node, next) => {
            return `<a href="${node.data.uri}"${
              node.data.uri.startsWith('https://workation.app')
                ? ''
                : ' target="_blank" rel="noopener"'
            }>${next(node.content)}</a>`
          },
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
            return `<img class="-image" src="https:${node.data.target.fields.file.url}?fm=jpg&fl=progressive&w=640&q=90" decoding="async" loading="lazy" />`
          },
          [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            if (node.data.target && node.data.target.fields.thumbnail) {
              return `
              <section class="EmbeddedHotel">
                <picture class="EmbeddedHotel__Thumb">
                  <source srcset="https:${node.data.target.fields.thumbnail.fields.file.url}?fm=webp&w=640&q=90" type="image/webp">
                  <img src="https:${node.data.target.fields.thumbnail.fields.file.url}?fm=jpg&fl=progressive&w=640&q=90" decoding="async" loading="lazy" />
                </picture>
                <div class="EmbeddedHotel__Meta">
                  <b class="EmbeddedHotel__Name">${node.data.target.fields.name}</b>
                  <div class="EmbeddedHotel__Info">
                    <p class="EmbeddedHotel__City">${node.data.target.fields.city}</p>
                    <p class="EmbeddedHotel__Address">${node.data.target.fields.address}</p>
                  </div>
                  <a class="EmbeddedHotel__Link" href="/hotels/${node.data.target.sys.id}">宿を確認する</a>
                </div>
              </section>
            `
            }
            if (node.data.target) {
              return `
              <div class="EmbeddedHotel">
                <div class="EmbeddedHotel__MetaUnit">
                  <p class="EmbeddedHotel__Name">${node.data.target.fields.name}</p>
                  <div class="EmbeddedHotel__CityAddressUnit">
                    <p class="EmbeddedHotel__City">${node.data.target.fields.city}</p>
                    <p class="EmbeddedHotel__Address">${node.data.target.fields.address}</p>
                  </div>
                  <a class="EmbeddedHotel__Link" href="/hotels/${node.data.target.sys.id}">宿を確認する</a>
                </div>
              </div>
            `
            }
            return ''
          },
          [BLOCKS.PARAGRAPH]: (node, next) =>
            `<p>${next(node.content).replace(/\n/g, `<br />`)}</p>`,
        },
      }
      return documentToHtmlString(obj, options)
    },
  })
}

export default contentfulPlugin
