import type { Middleware } from '@nuxt/types'

const isEmailVerified: Middleware = async ({ app, store, redirect }) => {
  if (
    process.server ||
    !store.getters['auth/isAuthenticated'] ||
    !store.getters['auth/isRegistered']
  ) {
    return
  }
  const usersSecureDoc = await app.$fire.firestore
    .doc(`users_secure/${store.getters['auth/loginUid']}`)
    .get()
    .catch((error) => {
      app.$sentry.captureException(error)
      return null
    })
  const isVerified = usersSecureDoc?.get('email_verified')
  if (isVerified) {
    return
  }
  redirect(`/users/${store.getters['auth/loginUid']}/edit`)
}

export default isEmailVerified
